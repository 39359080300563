<template>
	<div class="left-check-type flex">
		<div class="check-box">
			<p class="check-title">Dates</p>
			<div class="picker-box date-picker-box">
				<div class="date-picker flex justify_content_sb">
					<span>{{ startDate }} - {{ endDate }}</span>
					<img
						src="https://res.metaera.hk/resources/assets/images/active/date_tu.svg"
						class="date-picker-img"
					/>
				</div>
				<el-date-picker
					@change="changeDateVal"
					v-model="dateVal"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				>
				</el-date-picker>
			</div>
		</div>
		<div class="check-box" v-if="false">
			<p class="check-title">Locations</p>
			<div class="picker-box">
				<div class="select-box flex justify_content_sb">
					<span>{{ locationValue }}</span>
					<img
						src="https://res.metaera.hk/resources/assets/images/active/4_1.png"
					/>
				</div>
				<el-select
					v-model="locationValue"
					placeholder="请选择"
					@change="changeLocationValue"
				>
					<el-option
						v-for="item in areaOptions"
						:key="item"
						:label="item"
						:value="item"
					>
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="check-box" v-if="!type">
			<p class="check-title">Categories</p>
			<div class="picker-box">
				<div class="select-box flex justify_content_sb">
					<span>{{ categoryValue }}</span>
					<img
						src="https://res.metaera.hk/resources/assets/images/active/4_1.png"
					/>
				</div>
				<el-select
					v-model="categoryValue"
					placeholder="请选择"
					@change="changeCategoryValue"
				>
					<el-option
						v-for="item in categoryOptions"
						:key="item"
						:label="item"
						:value="item"
					>
					</el-option>
				</el-select>
			</div>
		</div>
	</div>
</template>

<script>
import { newBus } from "@/components/pc/topHeader2.vue";
import Vue from "vue";
export default {
	props: ["type"],
	data() {
		return {
			dateVal: "",
			startDate: "开始日期",
			endDate: "结束日期",
			locationValue: "全部",
			categoryValue: "全部",
			localLanguage: 0,
			areaOptions: [
				"Afterparty",
				"Meetup",
				"Summit",
				"Networking",
				"Dinner / Gala",
				"Hackathon",
				"Workshop",
				"Other",
			],
			categoryOptions: [
				"Afterparty",
				"Meetup",
				"Summit",
				"Networking",
				"Dinner / Gala",
				"Hackathon",
				"Workshop",
				"Other",
			],
		};
	},

	mounted() {
		newBus.$on("global-notification", (data) => {
			if (
				this.startDate == "开始日期" ||
				this.startDate == "Start Date" ||
				this.startDate == "開始日期"
			) {
				let localLanguage = sessionStorage.getItem("localLanguage");
				if (localLanguage == "繁体中文") {
					this.startDate = "開始日期";
					this.endDate = "結束日期";
					this.localLanguage = 1;
				} else if (localLanguage == "english") {
					this.startDate = "Start Date";
					this.endDate = "End Date";
				} else {
					this.startDate = "开始日期";
					this.endDate = "结束日期";
				}
			}
		});

		let localLanguage = sessionStorage.getItem("localLanguage");
		if (
			this.startDate == "开始日期" ||
			this.startDate == "Start Date" ||
			this.startDate == "開始日期"
		) {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.startDate = "開始日期";
				this.endDate = "結束日期";
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.startDate = "Start Date";
				this.endDate = "End Date";
			} else {
				this.startDate = "开始日期";
				this.endDate = "结束日期";
			}
		}
	},
	methods: {
		changeDateVal() {
			if (!this.dateVal) return;
			console.log(
				"新的日期，看看对不对",
				this.dateVal,
				this.dateVal[0].getTime()
			);
			this.startDate = this.$pvuv.currentDate(this.dateVal[0], 1);
			this.endDate = this.$pvuv.currentDate(this.dateVal[1], 1);
			this.$emit("changeFilter", {
				startDate: this.startDate,
				endDate: this.endDate,
				locationValue: this.locationValue,
				categoryValue: this.categoryValue,
			});
		},
		changeLocationValue() {
			this.$emit("changeFilter", {
				startDate: this.startDate,
				endDate: this.endDate,
				locationValue: this.locationValue,
				categoryValue: this.categoryValue,
			});
			console.log(this.locationValue);
		},
		changeCategoryValue() {
			this.$emit("changeFilter", {
				startDate: this.startDate,
				endDate: this.endDate,
				locationValue: this.locationValue,
				categoryValue: this.categoryValue,
			});
		},
		resetVal() {
			this.dateVal = "";
			this.startDate = "开始日期";
			this.endDate = "结束日期";
			this.locationValue = "全部";
			this.categoryValue = "全部";
			this.$emit("changeFilter", {
				startDate: this.startDate,
				endDate: this.endDate,
				locationValue: this.locationValue,
				categoryValue: this.categoryValue,
			});
		},
	},
};
</script>

<style scoped>
.check-box {
	margin-top: clamp(20px, calc(100vw * 3 / 144), 50px);
}
.check-box:nth-of-type(1) {
	margin-right: calc(100vw * 60 / 1440);
}
.check-box:nth-of-type(2) {
	margin-right: calc(100vw * 40 / 1440);
}
.check-title {
	margin-bottom: 11px;
	font-size: 18px;
	font-weight: bold;
	line-height: 18px;
	/* 纯白 */
	color: #ffffff;
}

.picker-box {
	position: relative;
	width: calc(100vw * 170 / 1440);
}
.date-picker-box {
	width: calc(100vw * 305 / 1440);
	max-width: 330px;
}
.date-picker,
.select-box {
	align-items: center;
	box-sizing: border-box;
	height: 40px;
	line-height: 18px;
	border: 1.5px solid #fff;
	border-radius: 20px;
	padding: calc(100vw * 9 / 1440) calc(100vw * 20 / 1440);
	font-weight: 500;
	color: #ffffff;
}
.date-picker span,
.select-box span {
	font-size: clamp(10px, calc(100vw * 18 / 1440), 18px);
}
.date-picker img {
	width: clamp(10px, calc(100vw * 22 / 1440), 22px);
	height: clamp(10px, calc(100vw * 22 / 1440), 22px);
	/* margin-left: 9px; */
}
.select-box img {
	width: clamp(10px, calc(100vw * 18 / 1440), 18px);
	height: clamp(10px, calc(100vw * 18 / 1440), 18px);
	transform: rotate(90deg);
}
</style>

<style>
.picker-box .el-range-editor.el-input__inner,
.picker-box .el-select {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
	width: 100%;
}
.ac_calendar_date .el-input__prefix {
	display: none;
}
.ac_calendar_date .el-input__inner {
	background: rgba(0, 0, 0, 0);
	border: 0 solid rgba(0, 0, 0, 0);
	color: #ffffff;
}
.ac_calendar_date {
	background: rgba(0, 0, 0, 0);
}
</style>
